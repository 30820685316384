import { Link, Typography } from '@mui/material';
import * as Sentry from '@sentry/react';
import { getSendWhatsAppMessageUrl } from '@wls-solucoes/lets-eat-core';
import { AxiosResponse } from 'axios';
import React from 'react';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { appHistory } from '../../helpers/utils/appHistory';
import { handleUserTokenExpired } from '../../redux/reducers/user/userActions';
import { store } from '../../redux/store';

const hideErrorMessageList = [
  'LoyaltyPlanNotFoundedException',
  'Exception',
  'ShipayRefundException',
];

export const errorInterceptor = (error: any): Promise<any> => {
  const response = error.response as AxiosResponse;
  const status = response?.status;

  if (status === 401 && !response?.config?.url?.startsWith('/login')) {
    store.dispatch(handleUserTokenExpired());
    appHistory.push('/login');
  }

  if (response?.data.errorName === 'ShipayRefundException') {
    const MySwal = withReactContent(Swal);

    MySwal.fire({
      icon: 'error',
      title: 'Não foi possível realizar o estorno',
      html: (
        <>
          <Typography>
            Isso pode ter acontecido devido a uma instabilidade no banco
            parceiro ou não há saldo suficiente na conta transitória para
            realizar o estorno.
          </Typography>

          <Typography>
            Em caso de dúvida entre em contato com o suporte Shipay:{' '}
            <Link
              href={getSendWhatsAppMessageUrl('5511949418625', '')}
              target="_blank"
            >
              1194941-8625
            </Link>{' '}
            e informe o id do pagamento ({response.data.extra.shipayPaymentId}).
          </Typography>
        </>
      ),
    });
  }

  if (
    response?.config.headers.hideErrorMessage ||
    hideErrorMessageList.includes(response?.data?.errorName)
  ) {
    return Promise.reject(response?.data);
  }

  if (response?.data?.errorName === 'SimultaneousUserLimitExceededException') {
    store.dispatch(handleUserTokenExpired());
    appHistory.push('/ex/planOffer');
  } else if (response?.data?.errorName === 'EstablishmentClosedException') {
    Swal.fire({
      icon: 'error',
      title: 'Ops...',
      text: 'Infelizmente o estabelecimento acabou de fechar :(',
    });
  } else if (response?.data?.message !== undefined) {
    toast.error(response.data.message);
  } else if (response !== undefined) {
    toast.error('Erro desconhecido');
    Sentry.captureException({
      ...error,
      message: `Erro desconhecido:: ${error?.message}`,
    });
  }

  return Promise.reject(error);
};
