import preval from 'preval.macro';

const {
  REACT_APP_API_URL,
  REACT_APP_BASE_URL,
  REACT_APP_LP_URL,
  REACT_APP_ENV,
  REACT_APP_GA_ID,
  REACT_APP_SENTRY_DSN,
  REACT_APP_SENTRY_ENVIRONMENT,
  REACT_APP_LOGO_URL,
  REACT_APP_LOGO_URL_WHITE,
  REACT_APP_ICON_URL,
  REACT_APP_ICON_URL_WHITE,
  REACT_APP_VERSION,
  REACT_APP_ID,
  REACT_APP_GOOGLE_MAPS,
  REACT_APP_ONE_SIGNAL_APP_ID,
  REACT_APP_SUPPORT_PHONE_NUMBER,
  REACT_APP_MERCADO_PAGO_DOC_LINK,
  REACT_APP_CHECKOUT_URL,
  REACT_APP_SHIPAY_FEE,
  REACT_APP_KDS_URL,
  REACT_APP_PDV_URL,
  REACT_APP_NFE_URL,
  REACT_APP_MIX_PANEL_TOKEN,
  REACT_APP_MIX_PANEL_PROXY,
  REACT_APP_MIX_PANEL_DEBUG,
  REACT_APP_BEAMER_PRODUCT_ID,
  REACT_APP_BEAMER_UPDATE_TIME,
} = process.env;

const isProd = REACT_APP_ENV === 'prod';
const isDev = REACT_APP_ENV === 'dev';
const isLocal = REACT_APP_ENV === 'local';

const REACT_APP_BUILD_TIME = preval`module.exports = Date.now();`;

export const env = {
  REACT_APP_API_URL,
  REACT_APP_BASE_URL,
  REACT_APP_LP_URL,
  REACT_APP_ENV,
  REACT_APP_GA_ID,
  REACT_APP_SENTRY_DSN,
  REACT_APP_SENTRY_ENVIRONMENT,
  REACT_APP_LOGO_URL,
  REACT_APP_LOGO_URL_WHITE,
  REACT_APP_ICON_URL,
  REACT_APP_ICON_URL_WHITE,
  REACT_APP_VERSION,
  REACT_APP_ID,
  isProd,
  isDev,
  isLocal,
  REACT_APP_BUILD_TIME,
  REACT_APP_GOOGLE_MAPS,
  REACT_APP_ONE_SIGNAL_APP_ID,
  REACT_APP_SUPPORT_PHONE_NUMBER,
  REACT_APP_MERCADO_PAGO_DOC_LINK,
  REACT_APP_CHECKOUT_URL,
  REACT_APP_SHIPAY_FEE,
  REACT_APP_KDS_URL,
  REACT_APP_PDV_URL,
  REACT_APP_NFE_URL,
  REACT_APP_MIX_PANEL_TOKEN,
  REACT_APP_MIX_PANEL_PROXY,
  REACT_APP_MIX_PANEL_DEBUG,
  REACT_APP_BEAMER_PRODUCT_ID,
  REACT_APP_BEAMER_UPDATE_TIME: Number(REACT_APP_BEAMER_UPDATE_TIME ?? 30),
};
