import { PrintConfigs } from '@wls-solucoes/lets-eat-core';
import {
  DeliveriesReportsResult,
  GeneralReportsResult,
  GeneralSurveyReports,
  ItemsReportsResult,
  PersonsReport,
  PizzaReports,
  ReportsBase,
  SurveyReportsResult,
} from '@wls-solucoes/lets-eat-reports';
import {
  AiquefomeConfiguration,
  BlockedItems,
  CashFlow,
  CashFlowConfig,
  CashFlowFilters,
  CashFlowHistory,
  Category,
  CategoryItems,
  ChatBot,
  CompletedLoyaltyPlanPaginatedData,
  CompletedLoyaltyPlanSortBy,
  CouponsPaginated,
  Establishment,
  IFoodConfiguration,
  LineOfWork,
  LoyaltyPlan,
  LoyaltyPlanUsageStatistics,
  OpeningHoursSettings,
  Order,
  OrderSettings,
  PermissionsGroup,
  PersonLoyaltyPlanPaginatedData,
  PersonLoyaltyPlanSortBy,
  Plan,
  PrintGroupCategory,
  PrintGroupSettings,
  Product,
  ProductPaginatedData,
  SimplifiedItemsCategory,
  Survey,
  User,
  UserNotificationPaginatedData,
} from '@wls-solucoes/lets-eat-types';
import { Address } from '../shared/models/address';
import { CatalogCategory } from '../shared/models/category';
import {
  CardFlag,
  FeeByAreaSettings,
  FeeByDistanceSettings,
  Neighborhood,
} from '../shared/models/establishment';
import { EstablishmentUser } from '../shared/models/establishmentUser';
import { GuideStep } from '../shared/models/guide';
import { Optional } from '../shared/models/optional';
import { Indicators } from '../shared/models/order/Indicators';
import { OrderCheckout } from '../shared/models/order/checkout/orderCheckout';
import { OrderPaginatedData } from '../shared/models/order/orderPaginatedData';
import { OrderFilters } from '../shared/models/order/v3/filters';
import { OrderPaginatedDataV3 } from '../shared/models/order/v3/orderPaginatedData';
import { PersonFilterBy, PersonSortBy } from '../shared/models/person';
import { PlanOrder } from '../shared/models/plan';
import { Post } from '../shared/models/post';
import { PromoItem } from '../shared/models/product';
import rootReducer from './reducers';
import { store } from './store';

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;

export interface GenericAction {
  type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
}

export interface ProgressIndicatorState {
  onFetchingData: Array<string>;
}

export const PROGRESS_INDICATOR_ADD = 'PROGRESS_INDICATOR_ADD';
export const PROGRESS_INDICATOR_REMOVE = 'PROGRESS_INDICATOR_REMOVE';

export interface ThemeColors {
  primaryColor?: string;
  secondaryColor?: string;
}

export interface ThemeState {
  colors: ThemeColors;
  mode: 'light' | 'dark';
}

export interface UserEstablishment {
  guid?: string;
  path?: string;
  thumbLogoPath?: string;
  name?: string;
}

export interface UserState {
  accessToken?: string;
  refreshToken?: string;
  establishments?: UserEstablishment | Array<UserEstablishment>;
  tokenExpirationDate?: string;
  user?: User & {
    establishments?: UserEstablishment | Array<UserEstablishment>;
  };
  preLoginToken?: string;
}

export const USER_LOGIN = 'USER_LOGIN';
export const USER_ESTABLISHMENT_LOGIN = 'USER_ESTABLISHMENT_LOGIN';
export const USER_SET_COMPLETED_STEPS = 'USER_SET_COMPLETED_STEPS';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_EXPIRE = 'USER_EXPIRE';

export interface EstablishmentState {
  establishment?: Establishment;
  linesOfWork?: LineOfWork[];
}

export const ESTABLISHMENT_GET = 'ESTABLISHMENT_GET';
export const ESTABLISHMENT_SET = 'ESTABLISHMENT_SET';
export const LINE_OF_WORK_GET = 'LINE_OF_WORK_GET';

export interface FeeByDistanceState {
  feeByDistance: FeeByDistanceSettings[];
}

export const FEE_BY_DISTANCE_GET = 'FEE_BY_DISTANCE_GET';

export interface FeeByAreaState {
  feeByArea: FeeByAreaSettings[];
}

export interface CategoriesState {
  categories?: Category[];
}

export const CATEGORIES_GET = 'CATEGORIES_GET';
export const CATEGORIES_REORDER = 'CATEGORIES_REORDER';
export const CATEGORY_REMOVE = 'CATEGORY_REMOVE';

export interface ProductState {
  product?: ProductPaginatedData;
  productDetails?: Product;
  categoryGuid: string;
  loading: boolean;
  failed: boolean;
}

export const PRODUCTS_GET = 'PRODUCTS_GET';
export const PRODUCTS_LOAD_MORE = 'PRODUCTS_LOAD_MORE';
export const PRODUCTS_RESET = 'PRODUCTS_RESET';
export const PRODUCTS_REORDER = 'PRODUCTS_REORDER';
export const PRODUCT_ADD = 'PRODUCT_ADD';
export const PRODUCT_UPDATE = 'PRODUCT_UPDATE';
export const PRODUCT_DELETE = 'PRODUCT_DELETE';
export const PRODUCT_CHANGE_VISIBILITY = 'PRODUCT_CHANGE_VISIBILITY';
export const PRODUCT_ADD_IMAGE = 'PRODUCT_ADD_IMAGE';
export const PRODUCT_DELETE_IMAGE = 'PRODUCT_DELETE_IMAGE';
export const PRODUCT_DEFINE_COVER = 'PRODUCT_DEFINE_COVER';

export interface OrderState {
  orders?: OrderPaginatedData;
  orderDetails?: Order;
  orderCheckout?: OrderCheckout;
  orderCheckoutDetails?: Order;
  newItemAdded: boolean;
}

export interface OrderV3State {
  orders?: OrderPaginatedDataV3;
  orderDetails?: Order;
  orderCheckout?: OrderCheckout;
  orderCheckoutDetails?: Order;
  filters?: OrderFilters;
  autoAcceptOrders?: boolean;
}

export interface OptionalState {
  optionals?: Optional[];
  isFetchingOptionals: boolean;
}

export const OPTIONAL_GET = 'OPTIONAL_GET';

export const ORDER_GET_PAGINATED = 'ORDER_GET_PAGINATED';
export const ORDER_GET_DETAILS = 'ORDER_GET_DETAILS';
export const ORDER_REMOVE_DETAILS = 'ORDER_REMOVE_DETAILS';
export const ORDER_CHECKOUT_ADD_ITEM = 'ORDER_CHECKOUT_ADD_ITEM';
export const ORDER_CHECKOUT_ADD_ORDER_DETAILS =
  'ORDER_CHECKOUT_ADD_ORDER_DETAILS';
export const ORDER_CHECKOUT_ADD_PAYMENT = 'ORDER_CHECKOUT_ADD_PAYMENT';
export const ORDER_CHECKOUT_ADD_DELIVERY_TYPE =
  'ORDER_CHECKOUT_ADD_DELIVERY_TYPE';
export const ORDER_CHECKOUT_ADD_DELIVERY_ADDRESS =
  'ORDER_CHECKOUT_ADD_DELIVERY_ADDRESS';
export const ORDER_CHECKOUT_REMOVE_DELIVERY_ADDRESS =
  'ORDER_CHECKOUT_REMOVE_DELIVERY_ADDRESS';
export const ORDER_CHECKOUT_REMOVE_ITEM = 'ORDER_CHECKOUT_REMOVE_ITEM';
export const ORDER_CHECKOUT_ADD_DELIVERY_FEE =
  'ORDER_CHECKOUT_ADD_DELIVERY_FEE';
export const ORDER_CHECKOUT_REMOVE_DELIVERY_FEE =
  'ORDER_CHECKOUT_REMOVE_DELIVERY_FEE';
export const ORDER_CHECKOUT_ADD_ADDRESSEE_INFORMATION =
  'ORDER_CHECKOUT_ADD_ADDRESSEE_INFORMATION';
export const ORDER_CHECKOUT_ADD_ESTABLISHMENT_PATH_NAME =
  'ORDER_CHECKOUT_ADD_ESTABLISHMENT_PATH_NAME';
export const ORDER_CHECKOUT_ADD_COUPON = 'ORDER_CHECKOUT_ADD_COUPON';
export const ORDER_CHECKOUT_REMOVE_COUPON = 'ORDER_CHECKOUT_REMOVE_COUPON';
export const ORDER_RESET_NEW_ITEM_ADDED_INDICATOR =
  'ORDER_RESET_NEW_ITEM_ADDED_INDICATOR';

export interface CatalogState {
  catalog?: CatalogCategory[];
  promotionList?: PromoItem[];
  openAccordions?: boolean[];
}

export const CATALOG_GET_CATEGORIES = 'CATALOG_GET_CATEGORIES';
export const CATALOG_GET_PROMOTIONS = 'CATALOG_GET_PROMOTIONS';
export const CATALOG_SAVE_PAGE_STATE = 'CATALOG_SAVE_PAGE_STATE';

export const CARDFLAG_GET = 'CARDFLAG_GET';

export interface CardFlags {
  debitCardFlags: CardFlag[];
  creditCardFlags: CardFlag[];
  voucherCardFlags: CardFlag[];
}

export interface CardFlagState {
  cardFlags?: CardFlags;
}

export interface LocalAddressState {
  addresses: Array<Address>;
}

export const LOCAL_ADDRESS_ADD = 'LOCAL_ADDRESS_ADD';

export interface NeighborhoodsState {
  neighborhoods?: Neighborhood[];
}

export const NEIGHBORHOODS_GET = 'NEIGHBORHOODS_GET';

export interface PlanState {
  plan?: Plan;
  planOrder?: PlanOrder;
  plansOptions?: { [key: string]: Plan };
}

export interface NotificationsState {
  isFetching: boolean;
  showNewNotificationPopup: boolean;
  userNotificationsPaginatedData: UserNotificationPaginatedData;
}

export interface EstablishmentUserState {
  establishmentUsers?: EstablishmentUser[];
}

export const ESTABLISHMENT_USER_GET = 'ESTABLISHMENT_USER_GET';

export const POST_GET = 'POST_GET';

export interface PostState {
  posts?: Post;
}

export interface PrinterState {
  printList: Array<{
    content: string;
    htmlStyle: string;
  }>;
}

export const INDICATORS_WEEK_GET = 'INDICATORS_WEEK_GET';

export interface IndicatorsState {
  today?: Indicators;
  yesterday?: Indicators;
  lastSevenDays?: Indicators;
  lastThirtyDays?: Indicators;
  thisMonth?: Indicators;
}

export const INDICATORS_MONTH_GET = 'INDICATORS_MONTH_GET';

export interface CouponsState {
  paginatedCoupons: CouponsPaginated;
  isFetchingCoupons: boolean;
}

export interface PanelGuideState {
  panelGuideAction: string;
  panelGuideOpen: boolean;
}
export const PANEL_GUIDE_OPEN = 'PANEL_GUIDE_OPEN';
export const PANEL_GUIDE_CLOSE = 'PANEL_GUIDE_CLOSE';
export const PANEL_GUIDE_UPDATE_ACTION = 'PANEL_GUIDE_UPDATE_ACTION';

export interface FetchPaginatedRequestParams {
  page?: number;
  pageSize?: number;
  query?: string;
}

export interface FetchPaginatedPersonsRequestParams
  extends FetchPaginatedRequestParams {
  sortBy?: PersonSortBy;
  filterBy?: PersonFilterBy;
}

export interface FetchLoyaltyPlanPersonsPaginatedRequestParams
  extends FetchPaginatedRequestParams {
  sortBy?: PersonLoyaltyPlanSortBy;
}

export interface FetchCompletedLoyaltyPlanPaginatedRequestParams
  extends FetchPaginatedRequestParams {
  sortBy?: CompletedLoyaltyPlanSortBy;
}

export interface PersonAddressRequestParams {
  address: Address;
  personGuid: string;
}

export const OPENING_HOURS_SETTINGS_GET = 'OPENING_HOURS_SETTINGS_GET';

export interface OpeningHoursSettingsState {
  openingHoursSettings?: OpeningHoursSettings;
  errorTime?: Date;
}

export interface CashFlowState {
  config?: CashFlowConfig;
  openedCashFlow?: CashFlow;
  history?: CashFlowHistory;
  historyFilters?: CashFlowFilters;
  cashFlowDetails?: CashFlow;
  fetchCashHistoryStatus?: 'pending' | 'fulfilled' | 'rejected';
}

export interface ReportsState {
  general: ReportsBase<GeneralReportsResult>;
  items: ReportsBase<ItemsReportsResult>;
  persons?: PersonsReport;
  deliveries: ReportsBase<DeliveriesReportsResult>;
  survey: ReportsBase<SurveyReportsResult>;
  surveyGeneralReport?: ReportsBase<GeneralSurveyReports>;
  pizzas?: PizzaReports;
}

export interface CatalogGuideState {
  firstTimeBuying: boolean;
}
export interface GuideState {
  guide: {
    currentStep: number;
    steps: GuideStep[];
  };
  isOpen: boolean;
}

export interface SideBarState {
  isRetracted: boolean;
}

export interface LoyaltyPlanState {
  personsSearchTerm: string;
  completedPlansSearchTerm: string;
  loyaltyPlan?: LoyaltyPlan;
  loyaltyPlanStatistics?: LoyaltyPlanUsageStatistics;
  paginatedLoyaltyPlanPersons: PersonLoyaltyPlanPaginatedData;
  paginatedCompletedLoyaltyPlan: CompletedLoyaltyPlanPaginatedData;
  fetchLoyaltyPlanStatisticsStatus?: 'pending' | 'fulfilled' | 'rejected';
  fetchPaginatedLoyaltyPlanPersonsStatus?: 'pending' | 'fulfilled' | 'rejected';
  fetchPaginatedCompletedLoyaltyPlanStatus?:
    | 'pending'
    | 'fulfilled'
    | 'rejected';
}

// eslint-disable-next-line
export interface IFoodState {
  iFoodConfiguration?: IFoodConfiguration;
}

export interface AiqfomeState {
  aiqfomeConfiguration?: AiquefomeConfiguration;
}

export interface UserPermissionState {
  permissions: PermissionsGroup[];
}

export interface SurveyState {
  survey?: Survey;
}

export interface PrintGroupsState {
  printGroupsSettings?: PrintGroupSettings;
  printGroupAvailableCategories?: PrintGroupCategory[];
  filteredPrintGroupAvailableCategories?: PrintGroupCategory[];

  groupsConfig: Array<{
    groupGuid?: string;
    configs: PrintConfigs;
  }>;
}

export interface OrderSettingsState {
  orderSettings?: OrderSettings;
}

export interface CategoryFilterValue {
  guid: string;
  name: string;
}

export interface ProductV3State {
  isFetchingCategories: boolean;
  isFetchingBlockedItems: boolean;
  isFetchingSimplifiedCategories: boolean;
  showCatalogGuide: boolean;
  simplifiedCategories: SimplifiedItemsCategory[];
  categoriesOnFetchingItems: string[];
  categoriesOnLoading: string[];
  itemsOnLoading: string[];
  categories?: CategoryItems[];
  blockedItems?: BlockedItems;
  categoryFilterValues?: CategoryFilterValue[];
  categoryGuid?: string;
  query: string;
}

export interface ChatbotState {
  chatbot?: Partial<ChatBot>;
  unreadMessagesCount: number;
  hideChatbot: boolean;
}

export interface LocalOrderSettingsState {
  notificationVolume: number;
  showCategoryNameOnOrder: boolean;
  notificationSound: any;
  confirmationToFinishOrder: boolean;
  confirmationToCancelOrder: boolean;
}

export interface BlockDialogState {
  isOpen: boolean;
}

export interface UIControllerState {
  forceSideBarRetraction: boolean;
  hideAppBar: boolean;
}
