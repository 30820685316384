import {
  AuthServiceV4,
  CashFlowService,
  CategoryService,
  DeliveryServices,
  DiscountCodeService,
  HttpClientConfig,
  IHttpClient,
  KDSService,
  NcmService,
  NFService,
  OrderService,
  OrderSettingsService,
  PaymentSettingsService,
  PrintGroupSettingsService,
  ProductService,
  ReferralService,
  ShipayService,
  SimpleStorageService,
  SurveyService,
  TimeZoneService,
  UaiRangoService,
  UserNotificationService,
  UserPermissionService,
} from '@wls-solucoes/lets-eat-services';
import axios, { AxiosInstance } from 'axios';
import Parser from 'ua-parser-js';
import { env } from '../../env';
import AuthInterceptor from '../interceptors/authInterceptor';
import { errorInterceptor } from '../interceptors/errorInterceptor';
import {
  successMessageRequestInterceptor,
  successMessageResponseInterceptor,
} from '../interceptors/successMessageInterceptor';

class AxiosAdapter implements IHttpClient {
  Api: AxiosInstance;

  constructor() {
    const parser = new Parser();
    const { os, browser } = parser.getResult();

    this.Api = axios.create({
      baseURL: env.REACT_APP_API_URL,
    });

    this.Api.defaults.headers = {
      'x-api-version': 2,
      'x-app-id': env.REACT_APP_ID ?? '',
      'x-app-version': env.REACT_APP_VERSION ?? '',
      'x-os-name': os.name,
      'x-platform-name': browser.name,
    } as any;
    this.Api.interceptors.request.use(AuthInterceptor);
    this.Api.interceptors.request.use(successMessageRequestInterceptor);
    this.Api.interceptors.response.use(
      successMessageResponseInterceptor,
      errorInterceptor
    );
  }

  async get(url: string, config?: HttpClientConfig) {
    return this.Api.get(url, config);
  }

  async post(url: string, data?: any, config?: HttpClientConfig) {
    return this.Api.post(url, data, config);
  }

  async put(url: string, data?: any, config?: HttpClientConfig) {
    return this.Api.put(url, data, config);
  }

  async patch(url: string, data?: any, config?: HttpClientConfig) {
    return this.Api.patch(url, data, config);
  }

  async delete(url: string, config?: HttpClientConfig) {
    return this.Api.delete(url, config);
  }
}

const axiosAdapter = new AxiosAdapter();

export const cashFlowService = new CashFlowService(axiosAdapter);
export const surveyService = new SurveyService(axiosAdapter);
export const userPermissionService = new UserPermissionService(axiosAdapter);
export const discountCodeService = new DiscountCodeService(axiosAdapter);
export const productService = new ProductService(axiosAdapter);
export const orderSettingsService = new OrderSettingsService(axiosAdapter);
export const categoryService = new CategoryService(axiosAdapter);
export const userNotificationService = new UserNotificationService(
  axiosAdapter
);
export const printGroupSettingsService = new PrintGroupSettingsService(
  axiosAdapter
);
export const orderService = new OrderService(axiosAdapter);
export const kdsService = new KDSService(axiosAdapter);
export const timeZoneService = new TimeZoneService(axiosAdapter);
export const shipayService = new ShipayService(axiosAdapter);
export const referralService = new ReferralService(axiosAdapter);
export const uaiRangoService = new UaiRangoService(axiosAdapter);
export const simpleStorageService = new SimpleStorageService(axiosAdapter);
export const deliveryServices = new DeliveryServices(axiosAdapter);
export const paymentSettingsService = new PaymentSettingsService(axiosAdapter);
export const authServiceV4 = new AuthServiceV4(axiosAdapter);
export const nfService = new NFService(axiosAdapter);
export const ncmService = new NcmService(axiosAdapter);

const { Api } = axiosAdapter;

export default Api;
