/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { OrderSummary } from '@wls-solucoes/lets-eat-types';
import { OrderV3State } from '../../types';
import {
  fetchNewOrder,
  fetchOrderDetails,
  fetchPaginatedOrders,
  refreshPaginatedOrders,
  updateOrder,
} from './actions';

const initialState: OrderV3State = {
  filters: {
    hideCancelled: true,
    hideFinished: true,
  },
};

export const ordersV3Slice = createSlice({
  name: 'ordersV3',
  initialState,
  reducers: {
    setAutoAccept(state, { payload }) {
      state.autoAcceptOrders = payload;
    },
    setOrderDetails: (state, { payload }) => {
      state.orderDetails = payload;
    },

    clearOrderDetails: (state) => {
      state.orderDetails = undefined;
    },

    setOrderFilters(state, { payload }) {
      state.filters = payload;
    },
    setOrder(state, { payload }: { payload: OrderSummary }) {
      if (!state.orders?.result) return;
      state.orders.result = state.orders.result.map((order) =>
        order.guid === payload.guid ? payload : order
      );
    },
  },
  extraReducers: {
    [fetchPaginatedOrders.fulfilled.type]: (state, { payload }) => {
      state.orders = payload;
    },
    [refreshPaginatedOrders.fulfilled.type]: (state, { payload }) => {
      state.orders = payload;
    },
    [fetchOrderDetails.fulfilled.type]: (state, { payload }) => {
      state.orderDetails = payload.data;
    },
    [fetchNewOrder.fulfilled.type]: (state, { payload }) => {
      if (state.orders && state.orders.currentPage === 1) {
        state.orders.result = [
          payload,
          ...(state.orders?.result.slice(0, 17) ?? []),
        ];
      }
    },
    [updateOrder.fulfilled.type]: (state, { payload }) => {
      if (state.orders) {
        const updatedOrdersArray = state.orders.result.map((order) => {
          if (order.guid === payload.guid) {
            return payload;
          }
          return order;
        });

        state.orders.result = updatedOrdersArray;
      }
    },
  },
});

export default ordersV3Slice.reducer;
export const {
  setOrderDetails,
  clearOrderDetails,
  setOrderFilters,
  setAutoAccept,
  setOrder,
} = ordersV3Slice.actions;
